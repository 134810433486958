.dash1_row3 {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}
.mystat_cards {
  width: 22%;
  height: 175px;
  background-size: contain;
  object-fit: contain;
  background-repeat: no-repeat;
  text-align: center;
}
.mystat_row1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 25px 0;
}

h3.mystat_h3 {
  margin: 0;
  margin-top: 22px;
  font-size: 18px;
  font-weight: 500;
}
h2.mystat_h2 {
  margin: 10px;
  color: #44CDDE;
  font-weight: 500;
  font-size: 32px;
}
p.mystat_p1 {
  margin: auto;
  background: #28B1CB33;
  width: 70px;
  line-height: 30px;
  border-radius: 5px;
}
.mystats {
  padding: 0 30px;
  padding-bottom: 10px;
  margin-top: 50px;
}
.mystat_card {
  display: flex;
  width: 97%;
  box-shadow: 0px 0px 20px #dddddd41;
  border-radius: 15px;
  padding: 20px;
  flex-direction: column;

}

.mystat_card1 {
  padding: 0 20px;
  width: 100%;
  border-right: 1px solid #e8e8e8;
  height: 330px;
}
.mystat_card2 {
  padding: 0 20px;
  width: 47%;
  border-top: 1px solid #e8e8e8;
  height: 300px;
}

p.mystat_p2 {
  color: #8F6EFC;
  font-weight: 500;
  margin: 0;
}
.mystat_card_row1 {
  display: flex;
}
.mystat_row3 {
  margin-bottom: 30px;
}

/*--------------------------------------------------------------------------------------------------------------------------*/

svg#SvgjsSvg1024,
svg#SvgjsSvg1001{
  margin-top: 20px;
}
.small_checkin_box {
  display: flex;
  justify-content: center;
  margin-top: -8px;

}
.small_xhexkin_xol1 {
  width: 29%;
}
p.small_checkin_p1 {
  margin: 0;
  color: #fff;
  font-weight: 900;
}
p.small_checkin_p2 {
  margin: 0;
  margin-left: 4px;
  font-weight: 600;
}
p.pro_name1 {
  color: #802df1;
  font-size: 11px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.dash_small_card {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E8FAFC;
  border: 1px solid #aadbe0;
  border-radius: 10px;
}