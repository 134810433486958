@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

*::-webkit-scrollbar {
  display: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

a {
  color: #121212 !important;
}
/* div{
  text-align: center;
} */
.reroutetoDash{
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.GtdButton{
  font-weight:600;
  margin-left: 38px;
    padding: 5px 20px;
    background: lightgreen;
    border-radius: 10px;
}
.GtdButton:hover{
  box-shadow: 0px 0px 18px 9px lightgreen;
}
.sr-only{
  display: none;
}
.spinner-grow {
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow;
}
.text-primary {
  color: #ad85ee !important;
}
.jsGxNl {

  width: 100%;
  color: black;
  font-size: 12px;
  font-weight: 500;
  display: none !important;
}

.ixKURx:not(:last-of-type) {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #FAFAFA;
  margin-bottom: 10px;
}
.ixKURx {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  color: black;
  background-color: #F2F2F2;
  min-height: 48px;
  background: white !important;
  border-radius: 12px !important;
  padding: 10px 6px !important;
}
.p_login {
  display: flex;
  width: 100%;
  min-height: 760px;
  background-color: #fff;
}
.log_col1 {
  width: 70%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

img.login_bg {
  width: 98%;
  height: 100%;
}
.log_col2 {
  width: 30%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.login_button {
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-top: 50px;
  cursor: pointer;
}

h2.log_sub {
  color: #4476bd;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
}
h1.log_name {
  color: #4476bd;
  font-size: 34px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0px;
}

p.log_p1 {
  color: #fff;
  margin-left: 139px;
  margin-top: -36px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  margin-left: 58px;

}

img.login_but {
  width: 100%;
  height: 38px;
}


/*
#9D81FF - p small name
*/
.profile_box {
  display: flex;
}

.dash_width {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.pro_col1 {
  margin: 3px 11px;
  display: flex;
}
.pro_col2 {
  width: 90px;
  margin: 12px;
  margin-top: 20px;
}
h1.pro_name {
  font-size: 14px;
  margin: 0px;
  text-transform: capitalize;
  width: 150px;
}
p.d1_p1 {
  font-size: 9px;
  margin: 1px;
  color: #9d81ff;
}
.dash1_head {
  margin-top: 26px;
}
.pro_col3 {
  margin-top: 30px;
  margin-left: 50px;
}
p.back {
  color: #9d81fc;
  text-transform: capitalize;
  font-size: 13px;
  margin-left: 10px;
}

h1.dash1_head_name {
  text-transform: capitalize;
  color: #8258ed;
  font-size: 18px;
  font-weight: 400;
}
.add_tl_col3 {
  width: 156px;
}

.launch_row3,
.s_t_inner_row,
.set_inner_row1,
.add_tl_col2,
.d1_row41,
.content_row1,
.pro_col3,
.dash1_row3,
.d1__row2,
.d_date1,
.d_date,
.d1_row11,
.d1_col5,
.d1_row1,
.dash1_row2 {
  display: flex;
}

.d1_col1 {
  width: 85%;
  padding: 20px;
}
.d1_col2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  width: 18%;
  padding: 20px 15px;
  min-height: 638px;
}
.d1_card {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
}

p.d1_p3 {
  margin: 3px;
  font-size: 10px;
  color: #5c5c5c;
}
p.d1_p2 {
  color: #8258ed;
  font-size: 12px;
  margin: 1px;
}
.d1_col7 {
  margin-top: 6px;
  margin-left: 10px;
}

.d1_col3 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  height: 20px;
  padding: 10px;
}
.d1_col8 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 20px;
  padding: 10px;
  width: 78px;
  box-shadow: 7px 6px 9px #eeeeee;
  display: flex;
  justify-content: center;

}
.f_arrow {
  height: 80%;
  width: 100%;
}
.location_card {
  position: absolute;
  z-index: 5;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-top: none;
  padding: 0 9px;
  margin-left: -11px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 80px;
  text-align: left;
  box-shadow: 7px 7px 11px #eeeeee;

}
p.d1_p5 {
  font-size: 11px;
  color: #a2a2a2;
  margin-left: 5px;
}
img.drop_down {
  margin-left: 6px;
  margin-bottom: 1px;
}
img.hr_line {
  width: 18px;
}

p.d1_p4 {
  font-size: 12px;
  margin: 0;
}

.d1_col8 {
  margin-left: 20px;
}
.d1_row1 {
  justify-content: space-between;
}

.d1_col22,
.d1_col21 {
  width: 50%;
  padding: 15px;
}
p.d1_p21 {
  margin: 0;
  color: #db23fb;
  font-size: 14px;
}

p.d1_p22 {
  font-size: 9px;
  margin: 0px;
  margin-top: 5px;
}

.d1_card1 {
  width: 30%;
}
img.img_d1_card {
  width: 100%;
}

.d1_col22 {
  display: flex;
  justify-content: space-between;
}

.d1_small_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  padding: 10px;
  width: 15%;
  text-align: center;
  color: #9f9f9f;
  cursor: pointer;
}

.d1_small_card1 {
  background: #9d81fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #ffff;
  border: 1px solid #d5d5d5;
  padding: 10px;
  width: 15%;
  text-align: center;
  cursor: pointer;
}



p.d1_p31 {
  font-size: 16px;
  margin: 3px;
}

.content {
  position: absolute;
  margin-top: -100px;
  padding: 10px;
  text-align: center;
  height: 80px;
  min-width: 143px;
}
p.d1_p23 {
  font-size: 11px;
  color: #fff;
  margin: 8px 20px 1px;
}

p.d1_p24 {
  font-size: 16px;
  color: #fff;
  margin: 4px 4px 8px;
  font-weight: 600;
}
p.d1_p25 {
  font-size: 11px;
  color: #fff;
  margin: -3px;
}

p.d1_p26 {
  font-size: 10px;
  margin: 11px 18px 2px;
  color: #fff;
}
p.d1_p27 {
  font-size: 14px;
  margin: -4px;
  font-weight: 600;
}

.d1_number {
  background: #e8e8e8;
  border-radius: 50%;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;

  color: #575757;
}

.d1_number1 {
  background: #18aa1b 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;

  color: #ffffff;
}
.d1_number2 {
  background: #ff4d4d 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 3px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;

  color: #ffffff;
}

p.d1_p41 {
  font-size: 12px;
  margin: 5px 8px;
  color: #9f9f9f;
}

.d1_row4 {
  display: flex;
  margin: 20px 12px;
  cursor: pointer;
}
img.rupee_svg {
  margin-bottom: 6px;
}
.content_d {
  display: flex;
  justify-content: center;
}

.add_tl_card {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #c1c1c180;
  border-radius: 10px;
  padding: 14px;
}

p.d1_p411 {
  margin: 2px 10px;
  font-size: 12px;
  font-weight: 600;
  color: #18aa1b;
}
.d1_row42 {
  margin: 12px 0;
}

.buttons1 {
  background: #f0edfa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  color: #8258ed;
  font-size: 10px;
  padding: 7px 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons2 {
  background: #9d81ff 0% 0% no-repeat padding-box;
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 5px;
  padding: 7px 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
  margin-left: 15px;
  justify-content: center;
  min-width: 80px;
}
.d1_row43 {
  display: flex;
  justify-content: space-between;
}

img.add_img {
  height: 12px;
  width: 12px;
  margin: 2px 0px;
}
p.d1_p42 {
  margin: 0;
  margin-left: 5px;
}
.add_tl_card1 {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px 40px;
  margin-bottom: 20px;
}
.add_tl_row1 {
  display: flex;
  justify-content: space-between;
}

h1.add_tl_col1 {
  color: #db23fb;
  font-weight: 400;
  margin: -5px 30px;
  font-size: 17px;
}
.add_tl_search {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b3b3b3d9;
  border-radius: 5px;
  display: flex;
  padding: 4px;
  width: 250px;
}
img.search_svg {
  width: 16px;
  height: 20px;
  margin: 0px 20px;
}

p.add_tl_p1 {
  margin: 2px;
  font-size: 11px;
  color: #6210e1;
}

.add_tl_card2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #dedede66;
  border-radius: 5px;
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  padding: 20px;
}

.add_tl_card2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #dedede66;
  border-radius: 5px;
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  padding: 12px;
}

.add_tl_p8,
.add_tl_p4,
.add_tl_p5,
.add_tl_p6,
.add_tl_p7,
p.add_tl_p2 {
  margin: 0;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.add_tl_col1 {
  padding: 0 25px;
}
p.add_tl_p3 {
  font-size: 10px;
  text-align: center;
  color: #929292;
  margin: 0;
  margin-top: 4px;
}

p.add_tl_p4 {
  color: #66e4b6;
}

p.add_tl_p5 {
  color: #732de5;
}

p.add_tl_p6 {
  color: #e946df;
}
p.add_tl_p7 {
  text-align: center;
  background: #d1eed1;
  border-radius: 5px;
  color: #18aa1b;
  padding: 10px 20px;
}
p.add_tl_p8 {
  text-align: center;
  background: #feedd5;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fba42d;
}

.set_date_row3,
.add_tl_row3 {
  text-align-last: end;
  margin: 25px;
}

input.up_t_save_button,
input.add_tl_save_button {
  border: none;
  background: #9d81ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 40px;
  color: #fff;
  cursor: pointer;
}
input.run_pay_button {
  border: none;
  background: #b9b9b9 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 40px;
  color: #fff;
}

/* .update_train_card {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 400px;
} */

/* .up_train_inner_card {
  background: #fff;
  border: 1px dashed #a0a0a0;
  border-radius: 5px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  align-content: center;
  align-items: center;
} */

p.up_t_p1 {
  margin: 8px;
  font-size: 12px;
  font-weight: 500;
}

p.up_t_p2 {
  font-size: 8px;
  color: #929292;
  margin: 0;
}
/* img.document_svg {
  width: 35px;
  height: 35px;
} */

.set_train_row1 {
  display: flex;
  justify-content: center;
}

.setup_trainer_Card {
  background: #ffffff;
  border: 1px solid #323c461a;
  width: 85%;
  padding: 10px;
}
p.set_t_p1 {
  color: #db23fb;
  margin: 0;
  font-size: 15px;
  margin-left: 20px;
}

p.set_t_p2 {
  margin: 5px;
  width: 15px;
}

.set_t_inner_card {
  background: #fafafa;
  padding: 10px;
  width: 85%;
  margin-top: 20px;
  margin-left: 40px;
  border-radius: 8px;
}
input#question2,
input#question {
  height: 30px;
  padding: 1px 15px;
  width: 75%;
  margin: 4px;
  border-radius: 2px;
  border: 1px solid #575757;
}
input#question1 {
  height: 30px;
  width: 100%;
  margin: 4px;
  border-radius: 2px;
  border: 1px solid #575757;
  padding: 5px 15px;
}

input#question2::-webkit-input-placeholder {
  color: #9d81fc;
}

input#question2::placeholder {
  color: #9d81fc;
}

.set_t_p21 {
  margin: 5px;
}
.set_done {
  text-align-last: end;
  width: 91%;
}

input.set_done_button {
  background: #edf2f2 0% 0% no-repeat padding-box;
  border: 1px solid #2e7d32;
  border-radius: 2px;
  color: #2e7d32;
  padding: 5px 15px;
  margin-top: 15px;
}
.set_add_q {
  margin-left: 40px;
}
input.set_add_q_button {
  background: #f6f2ff 0% 0% no-repeat padding-box;
  border: 1px solid #8258ed;
  border-radius: 5px;
  padding: 5px 15px;
  color: #8258ed;
  margin-bottom: 15px;
}
.s_t_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #575757;
  border-radius: 2px;
  font-size: 12px;
  padding: 6px;
  margin: 10px;
}

.s_t_small_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c7c7c7c9;
  border-radius: 2px;
  margin: 10px 0px;
  width: 25%;
}
.set_inner_row3 {
  display: flex;
  flex-wrap: wrap;
}
.s_t_small_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c7c7c7c9;
  border-radius: 2px;
  margin: 10px;
  padding: 5px 10px;
  width: 45%;
  font-size: 12px;
}
.set_inner_row4 {
  margin-top: 35px;
  margin-left: 20px;
}
.buttons3 {
  background: #f0edfa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  color: #8258ed;
  font-size: 11px;
  padding: 10px 19px;
  cursor: pointer;
}
.buttons4 {
  background: #9d81ff 0% 0% no-repeat padding-box;
  color: #fff;
  text-align: center;
  font-size: 11px;
  border-radius: 5px;
  padding: 10px 10px;
  cursor: pointer;
}

.d1_p412 {
  font-size: 12px;
  margin: 5px 8px;
  color: #ff4d4d;
}

.buttons5 {
  background: #9d81ff 0% 0% no-repeat padding-box;
  color: #fff;
  text-align: center;
  font-size: 11px;
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer;
  width: 50%;
}
div.update_next_button {
  display: flex;
  justify-content: flex-end;
}

.d1_update_card {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  margin-bottom: 80px;
}

.d1_up_col1 {
  width: 80%;
}
p.d1_up_p1 {
  font-size: 12px;
}

.d1_up_col2 {
  display: flex;
  width: 20%;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.d1_req_train {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8258ed;
  border-radius: 5px;
  padding: 5px 15px;
}
p.d1_req_p1 {
  font-size: 12px;
  color: #9d81fc;
  margin: 0;
}
.set_date_inner_card {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 94%;
  margin: AUTO;
  padding: 20px;
  height: 180px;
  text-align: start;
}
p.set_date_p1 {
  color: #db23fb;
  font-size: 14px;
  margin: 12px 0;
}
.set_date_row2 {
  display: flex;
}
.set_date_inner_card2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 10px;
  margin-right: 30px;
  width: 35%;
}
p.set_date_p2 {
  color: #5c5c5c;
  margin: 0 10px;
  font-size: 13px;
}

input#date {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: 6px 15px;
  margin: 18px 8px;
  width: 84%;
  color: black;
  height: 25px;
  font-size: 12px;
}

.set_date_row3 {
  margin-right: 10%;
}
/* .run_payroll_total,
.launch_project_total {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
} */
p.lch_p1 {
  color: #db23fb;
  margin: 10px 0;
  font-size: 15px;
}
.launch_row2 {
  margin: 0 20px;
  font-size: 10px;
}

.launch_row1 {
  margin: 0 20px;
}

.lch_col1 {
  width: 70%;
}
.lch_col2 {
  width: 30%;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
}
.payroll_button {
  border: none;
  background: #9d81ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
}
p.up_re_p2 {
  font-size: 10px;
  margin: 10px;
}
p.up_re_p1 {
  color: #db23fb;
  margin: 0 10px;
}
.upload_report_card {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px 30px;
}
.upl_re_row2 {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.project_detail_card {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  margin: 30px 0;
  padding: 20px 35px;
}
.proj_d_row1 {
  margin: 15px 0;
  margin-bottom: 30px;
}
.my_team_data {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  margin: 30px 0;
}
p.my_t_p1 {
  font-size: 16px;
  margin: 10px;
  color: #db23fb;
}
.my_t_row1 {
  display: flex;
  justify-content: space-between;
  width: 99%;
}
.my_t_row2 {
  margin: 10px;
}
.my_t_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 15px 80px #dedede65;
  border-radius: 15px;
  padding: 10px 20px;
  margin-bottom: 20px;
}
img.prof_img {
  height: 50px;
  width: 50px;
}

p.my_t_p5,
p.my_t_p4,
p.my_t_p2 {
  margin: 5px;
  font-size: 12px;
  margin-bottom: 0;
}
p.my_t_p2 {
  font-weight: 600;
}
p.my_t_p3 {
  font-size: 9px;
  margin: 0;
}
p.my_t_p4 {
  color: #5c5c5c;
}

p.my_t_p5 {
  color: #1ea056;
  font-weight: 600;
}

p.my_t_p10,
p.my_t_p9,
p.my_t_p8,
p.my_t_p6 {
  font-size: 15px;
  margin: 5px;
  font-weight: 500;
  margin-bottom: 0px;
}
p.my_t_p6 {
  color: #5524fb;
}

p.my_t_p11,
p.my_t_p7 {
  font-size: 9px;
  font-weight: 500;
  margin: 0;
}
p.my_t_p11 {
  color: #d87be8;
}
p.my_t_p9 {
  color: #36ba96;
}
p.my_t_p8 {
  color: #db23fb;
}
p.my_t_p10 {
  color: #7e5bf8;
}

.my_t_col1,
.my_t_col2,
.my_t_col3,
.my_t_col4,
.my_t_col5,
.my_t_col6,
.my_t_col7,
.my_t_col8,
.my_t_col9 {
  text-align: center;
  margin: 5px;
  min-width: 92px;
  flex: 1 1;
}

img.cal_svg {
  margin-left: 10px;
  cursor: pointer;
}

.my_statics_progress_bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #24e0e0;
  transition: width 0.6s ease;
}
.my_statics_data {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  margin: 30px 0;
}
.my_stats_row1 {
  display: flex;
}

img.my_stat_card {
  width: 190px;
  height: 110px;
  margin: 10px;
}

.my_stats_card1 {
  position: absolute;
  margin-top: -125px;
  text-align: center;
  margin-left: 55px;
}
p.my_stats_p1 {
  color: #fff;
  font-size: 17px;
  margin: 0;
  margin-top: 22px;
}
p.my_stats_p2 {
  color: #fff;
  margin: 3px;
  font-size: 18px;
}
img.my_stat_card1 {
  width: 140px;
  height: 100px;
  margin: 16px;
}
.my_stats_card2 {
  position: absolute;
  margin-top: -116px;
  margin-left: 12px;
  text-align: center;
  width: 150px;
}
p.my_stats_p3 {
  color: #fff;
  margin: 0;
  margin-top: 20px;
  font-size: 12px;
}
p.my_stats_p4 {
  color: #fff;
  margin: 1px;
}

.my_stats_col1 {
  width: 70%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 15px 40px #dedede7e;
  border-radius: 15px;
  min-height: 300px;
  margin: 10px;
  margin-right: 15px;
}

.my_stats_col2 {
  width: 30%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 15px 40px #dedede7e;
  border-radius: 15px;
  min-height: 300px;
  margin: 10px;
  margin-left: 15px;
}
.my_stats_row2 {
  display: flex;
}
p.my_stats_p5 {
  color: #db23fb;
  margin-left: 15px;
}
.my_stats_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 7px 40px #dedede1a;
  border-radius: 15px;
  margin: 15px;
  padding: 20px;
  display: flex;
}
.my_s_col0 {
  margin: 20px 0px;
}

.my_s_col1 {
  width: 120px;
  margin: 0 10px;
  text-align: center;
  margin-top: 25px;
}

.my_s_col2 {
  min-width: 85px;
  margin: 0 10px;
  text-align: center;
  margin-top: 25px;
}

p.my_s_p5 {
  font-size: 13px;
  font-weight: 600;
  margin: 1px;
}
p.my_s_p6 {
  font-size: 9px;
  margin: 0;
  color: #9f9f9f;
}
p.my_s_p7,
p.my_s_p8,
p.my_s_p9 {
  margin: 1px;
  font-size: 13px;
  font-weight: 500;
}
p.my_s_p8 {
  color: #9d81fc;
}
p.my_s_p9 {
  color: #1ea056;
}
.my_s_col3 {
  width: 42%;
  margin-top: 10px;
}
.my_stats_row5 {
  display: flex;
}
.my_statics_progress {
  display: flex;
  overflow: hidden;
  font-size: 0.60975rem;
  background-color: #eee;
  width: 100%;
  height: 12px;
  border-radius: 20px;
  margin: 15px;
}
p.my_s_p10 {
  font-size: 12px;
  width: 38%;
  margin: 12px 2px;
}
.my_t_c_row3,
.my_t_c_row1 {
  display: flex;
}

.my_t_c_col1 {
  width: 50%;
}
.my_t_c_row4 {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  margin: 15px;
  margin-bottom: 30px;
}
p.my_t_c_p1 {
  margin: 0;
  color: #673995;
  font-size: 13px;
}
.my_t_c_box5,
.my_t_c_box4,
.my_t_c_box3,
.my_t_c_box2,
.my_t_c_box1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
}

.my_t_c_box1 {
  border: 1px solid #10be4a;
  color: #0e9a11;
}
p.my_t_c_p2 {
  margin: 0;
  font-size: 12px;
}
.my_t_c_boxes {
  display: flex;
  margin: 8px 0;
}

.my_t_c_box2 {
  border: 1px solid #ff4f3f;
  color: #f83826;
  margin-left: 15px;
}
.my_t_c_box3 {
  border: 1px solid #9d81fc;
  color: #5524fb;
  position: absolute;
  margin-left: 290px;
}
p.my_t_c_p3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.my_t_c_box4 {
  background: #9d81fc41 0% 0% no-repeat padding-box;
  border: 1px solid #9d81fc;
  border-radius: 5px;
  color: #5524fb;
  width: 35%;
}

.my_t_c_box5 {
  background: #fba42d27 0% 0% no-repeat padding-box;
  border: 1px solid #fba42d;
  border-radius: 5px;
  width: 35%;
  color: #fa8700;
  margin-left: 50px;
}
.my_t_c_col2 {
  width: 50%;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 10px 15px 80px #dedede1a;
  border-radius: 15px;
  margin: 15px;
  margin-bottom: 30px;
}
.my_t_c_card {
  display: flex;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.my_t_row4 {
  margin: 5px 0;
}

.my_t_col11,
.my_t_col12,
.my_t_col13,
.my_t_col14,
.my_t_col15,
.my_t_col16,
.my_t_col17,
.my_t_col18,
.my_t_col19 {
  text-align: center;
  margin: 5px;
  min-width: 94px;
}
.setting_data {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
}
.setting_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}
.setting_row1 {
  display: flex;
  justify-content: space-between;
}
p.setting_p1 {
  color: #252733;
  font-weight: 500;
  margin: 5px;
}

img.edit_pro {
  width: 30px;
  height: 30px;
}
.my_t_row01 {
  margin-left: 5px;
}
.my_t_col01 {
  display: flex;
  width: 9%;
  text-align: center;
}
p.my_tl_p01 {
  margin: 5px;
  font-size: 14px;
  font-weight: 500;
}
p.my_tl_p02 {
  margin: 0;
  font-size: 11px;
  color: #9d81ff;
}
.my_t_col03 {
  width: 90%;
}
.my_t_row02 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}
img.cal_svg {
  margin-left: 10px;
  height: 30px;
}
.my_t_row04 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 15px;
}
.my_t_boxes {
  width: 33.33%;
  display: flex;
}
.my_t_box {
  background: #f9f9f9;
  border-radius: 10px;
  width: 70%;
  height: 50px;
  margin-bottom: 25px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p.my_t_p02 {
  margin: 0;
  font-size: 10px;
  color: #b2b2b2;
  text-align: center;
}
p.my_t_p03 {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
img.my_t_svg {
  width: 40px;
  height: 40px;
  margin-top: 14px;
}
img.my_t_qc_svg {
  width: 26px;
  height: 26px;
  margin: 20px 8px;
}
img.f_arrow_svg {
  margin-top: 55px;
}

.add_tl_pop {
  position: fixed;
  z-index: 11;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 660px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.add_pop_card {
  z-index: 10;
  background: #fff;
  box-shadow: 10px 15px 30px #00000029;
  border-radius: 10px;
  width: 60%;
  text-align: center;
  min-height: 350px;
  padding: 30px;
  margin-top: -250px;
}
p.add_pop_p1 {
  color: #9d81ff;
  font-size: 22px;
}
p.add_pop_p2 {
  width: 36%;
  margin: auto;
}
p.add_pop_p3 {
  width: 50%;
  margin: auto;
  padding: 20px;
  background: #f7f7f7;
  margin-top: 50px;
}
.add_pop_row1 {
  text-align-last: end;
  margin: 25px;
  position: absolute;
  bottom: 36%;
  right: 21%;
}
.add_pop_row2 {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
.close_row {
  display: flex;
  width: 98%;
  justify-content: flex-end;
}
img.close_but {
  position: absolute;
  width: 4%;
  cursor: pointer;
}

.dots_card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 6px 9px 12px #dedede81;
  border: 1px solid #D5D5D534;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  margin-left: -170px;
  margin-top: -20px;
  padding-right: 10px;

}

.dots_row1 {
  display: flex;
}
.dots_row2 {
  display: flex;
  margin-top: 10px;
}
img.dots_front {
  width: 10px;
  height: 10px;
  margin: 3px;
  margin-left: 15px;
}
p.dots_p1 {
  font-size: 10px;
  margin: 0;
  cursor: pointer;
}
p.dots_p2 {
  font-size: 10px;
  margin: 5px 0;
}
p.dots_p3 {
  background: #18AA1B33 0% 0% no-repeat padding-box;
  border: 1px solid #18AA1B;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 10px;
  margin: 0;
  color: #18AA1B;
  text-align: center;
  margin-left: 12px;
}
/*
#ebe6fe -bg small card
#DB23FB - pink
#802DF1 - text purple
#9D81FF - button
*/

.dash_card {
  background: #fff;
  width: 43%;
  margin: 20px;
  padding: 15px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
}

.dashboard {
  display: flex;
  width: 100%;
}
.dashboard_col1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  padding: 20px;
  min-height: 717px;
}
p.dash_pro_p1 {
  color: #8258ed;
  font-size: 13px;
  margin: 5px;
}
.dash_pro_row1 {
  display: flex;
  background: #d8d5ff 0% 0% no-repeat padding-box;
  margin: 0 -19px;
  border-right: 5px solid #9d81ff;
  margin-top: 20px;
  padding: 10px 25px;
}
img.dash_document {
  width: 25px;
  height: 25px;
  margin-top: 2px;
}
.dash_row1 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #88888843;
  height: 80px;
}


img.dash_profile {
  width: 45px;
  height: 45px;
  margin-top: 16px;
  margin: 0 3px;
  border-radius: 50%;
}
h3.dash_list_head {
  font: normal normal normal 16px/18px poppins;
  color: #832595;
  margin: 18px 26px 0px;
  text-transform: capitalize;
}

.dash_row3,
.dash_row2,
.sub_row,
.d_row2,
.d_row1 {
  display: flex;
}

.dash_row3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
img.company_pro {
  min-width: 55px;
  height: 55px;
  margin: 0px;
}
div.dash_p1 {
  font-size: 11px;
  margin: 17px 6px;
  color: #777777;
}
div.dash_h1 {
  font-size: 16px;
  margin: 13px;
  margin-left: 3px;
  font-weight: 400;
}

.dash_col1 {
  width: 65%;
  background: #ebe6fe;
  border-radius: 5px;
  margin-left: -15px;
}
.dash_col2 {
  width: 40%;
}

p.pro_name {
  color: #5c5c5c;
  font-size: 9px;
  display: flex;
  align-items: center;
  margin: 5px;
  margin-left: 15px;
}
p.pro_name1 {
  color: #802df1;
  font-size: 11px;
  display: flex;
  align-items: center;
}

.d_row2 {
  margin-top: 10px;
}

h2.d_header {
  color: #db23fb;
  font-size: 15px;
  font-weight: 400;
  margin: 5px;
  margin-top: 14px;
}

p.d_p1 {
  font-size: 10px;
  margin: 7px;
}

.d_row4 {
  display: flex;
  justify-content: flex-end;
}

.dash_button {
  padding: 10px 28px;
  border: none;
  border-radius: 5px;
  background: #9d81ff;
  color: #fff;
  font-size: 17px;
  margin: 10px;
  cursor: pointer;
  display: flex;
}

p.dash_b_p1 {
  margin: 0;
  font-size: 14px;
  margin-right: 10px;
}
img.view_but {
  height: 21px;
  width: 20px;
  margin-top: 1px;
}
a {
  text-decoration: none;
}
.dashboard_total {
  padding: 10px 50px;
}
.dashboard_col2 {
  width: 100%;
}
.d_row3 {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}
.dash_small_card {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e8fafc;
  border: 1px solid #e8fafc;
  border-radius: 10px;
}
p.dash_small_p1 {
  margin: 0;
  margin-top: 11px;
  font-size: 12px;
}
p.dash_small_p2 {
  margin: 10px;
  font-size: 20px;
  color: #44cdde;
}
p.dash_small_p3 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 13px;
  background: #28b1cb33;
  padding: 1px 5px;
}


.logout_box {
  cursor: pointer;
  background: #eaeaea;
  border-radius: 5px;
  width: 115px;
  height: 31px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 12px;
}
.dash_header_col1 {
  margin-left: 30px;
}
.p1-container {
  margin: 10px 30px;
}
.p1-select-tag-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.stats-card-container {
  display: flex;
  justify-content: space-evenly;
}
.my-card {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin-top: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 150px;
}
.light-green {
  background-image: url(/static/media/TOTAL_LEAD1.a5181055.svg) !important ;
  color: #72c1b1;
}

.light-orange {
  background-image: url(/static/media/APPROVED_LEAD1.1cfe35f8.svg) !important ;
  color: #ffa31d;
}
.light-brown {
  background-image: url(/static/media/TOTAL_CHECKIN1.9deb6ea9.svg) !important ;
  color: #00818D;
}
.cream {
  background-image: url(/static/media/TOTAL_ONBOARDING1.fe45df51.svg) !important ;
  color: #5B69CE;
}
.p1-select-tag {
  padding: 5px 10px;
  border-radius: 5px;
  color: #8f6efc;
  background: #e4ddff;
  border: 1px solid #805aff;
}
.p1-table-container {
  margin-top: 30px;
}
.makeFixed{
    position: fixed;
    width: 100%;
    height: 54px;
    z-index: 1;
    background-color: white;
}
.addCm-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 52px;
}
.monthly {
  font-weight: 500;
  font-size: 25px;
}


.my_card_p1 {
  font-size: 19px;
  color: #121212;
}
.my_card {
  padding: 2px 10px;
  border-radius: 5px;
  color: #121212;
  font-size: 12px;
  font-weight: 500;
}
.padding_card_p1 {
  background: #B6E8DC;
}
.padding_card_p2 {
  background: #FDD39A;
}
.padding_card_p3 {
  background: #C5EDF0;
}
.padding_card_p4 {
  background: #c3caff;
}
.button_add {
  border: none;
  background: #855bf5;
  color: #fff;
  border-radius: 5px;
  margin: 0 8px;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  width: 155px;
  height: 42px;
  align-items: center;
  cursor: pointer;
}
img.add_cm_image {
  width: 20px;
}

.city_manager_name {
  font-size: 23px;
  margin-left: 28px;
  color: #DB23FB;
}
.header-material {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 54px;
  align-items: center;
}
p.dash_header_p1 {
  margin: 0;
  font-size: 13px;
}
h1.dash_head_name {
  font: normal normal normal 22px/24px poppins;
  font-weight: 400;
  margin: 0;
  margin-left: 8px;
}
.dash_header_col2 {
  display: flex;
  align-items: flex-end;
  margin-left: 30px;
  margin-top: 20px;
}

.main-body{
  width:87vw;
  margin-top: 37px;
  min-height:100vh;
}
.search-container{
padding: 5px 20px;
border:1px solid lightgray;
border-radius: 20px;
background-color: white;
}
.search-input{
  outline: none;
  border: none;
  margin-right: 10px;
}
.filter-option{
  border: none;
  outline: none;
}

.table_data {
  display: flex;
  justify-content: center;
}
.card_table {
  background: #f2f2f2;
  box-shadow: 3px 3px 13px #dededebf;
  border-radius: 20px;
  width: 100%;
  padding: 20px;
}

.sc-hKFxyN.sc-eCApnc.sc-iqAclL.kXbQov.jFwrzr.rdt_TableCol {
  color: #855bf5;
}
/* .sc-jrsJWt.flbcPw.rdt_TableRow,
.sc-jrsJWt.hztQPW.rdt_TableRow {
  background: #fff;
  border-radius: 10px;
  margin: 2px 0;
  padding: 10px 0;
  border: 1px solid lightgray;
  justify-content: center;
} */
div#cell-6-undefined,
div#cell-3-undefined,
div#cell-1-undefined {
  color: #252733;
  font-weight: 600;
}

div#cell-5-undefined,
div#cell-7-undefined,
div#cell-2-undefined {
  color: #5c5c5c;
  font-size: 11px;
  font-weight: 500;
}

div#cell-4-undefined {
  color: #1ea056;
  font-size: 14px;
  font-weight: 500;
}
/* .data-table-extensions {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
button.print,
button.download {
  display: none;
}
button {
  border: none;
  background: #855BF5;
  color: #fff;
  padding: 4px 15px;
  border-radius: 5px;
  margin: 0 8px;
}
input.filter-text {
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  padding: 4px 15px;
  font-size: 14px;
}
input.filter-text:focus {
  outline: none;
}

.menu-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  /* margin-bottom:-37px; */
}

.rdt_TableRow {
  background-color: white;
}
.cm-pic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.myCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cmCity {
  font-size: 18px;
  color: #aa455c;
  font-weight: 300;
  margin: 4px 0;
}

.colTitle {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
}
.colValue {
  font-weight: 200;
  color: #5B3EBA;
  font-size: 27px;
  margin: 0;
  margin-top: 7px;
}
.onBoardStatus {
  background: #e7e7e7d1;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 8px;
}
/* .active {
  background: #ceeaa3d1;
} */
.inActive {
  background: #ff8f8fd1;
}
/* .hold {
  background: rgba(255, 166, 0, 0.356);
} */
.fixStatusCard {
  min-width: 110px;
  max-width: 129px;
}
.dateStyle {
  font-size: 9px;
  font-weight: 100;
  color: #9879ff;
}

.data-table-extensions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.col_subtitle {
  color: #B2B2B2;
  font-size: 10px;
  font-weight: 300;
  margin: 0;
  margin-top: -2px;
}

.col_value2 {
  color: #2DA0BC;
  font-weight: 500;
  font-size: 27px;
  margin: 0;
  margin-top: 7px;
}
.col_value3 {
  color: #121212;
  font-size: 11px;
  font-weight: 400;
  margin-top: -4px;
}
.hold {
  background: #FFB33933;
  color: #FF8900;
}
.active {
  background: #238E4827;
  color: #238E48;
}
.sc-jrsJWt.flbcPw.rdt_TableRow, .sc-jrsJWt.hztQPW.rdt_TableRow {
  background: #fff;
  border-radius: 10px;
  margin: 12px 0;
  padding: 10px;
  padding-top: 25px;
  justify-content: center;
  display: flex;
  align-items: flex-start;
}
.sc-gtsrHT.hxohyr.rdt_TableHead {
  display: none;
}
a.dropdown-item {
  padding: 0px;
}
.cm-image{
  height: 48px;
  width: 48px;
  object-fit: cover;
  border-radius: 50px;
}
.threedots:after {
  content: "\2807";
  font-size: 50px;
  color: #9e81fb;
}
.dropdown-menu.show {
  font-size: 9px;
  box-shadow: 0px 0px 1px 1px lightgrey;
  background: white;
  padding: 10px;
  border-radius: 10px 0px;
  color:#673695;
  z-index:1002;
}
a.dropdown-item:hover{
  background: lightgreen;
  padding:2px 5px;
  border-radius: 2px;
}

.dashtl1_row1 {
  padding: 0px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* .dashtl1_card_row {
  display: flex;
  justify-content: space-between;
  width: 50%;
} */
.dashtl_card {
  margin: 20px 0;
  box-shadow: 10px 15px 80px #dedede65;
  border-radius: 15px;
  padding: 20px;
  width: 49%;
  background: #fff;
}
.dashtl_card_row1 {
  display: flex;
  justify-content: space-between;
}
.dashtl1_card_col1 {
  display: flex;
}
img.prof_img {
  width: 100px;
  height: 100px;
  margin: 15px 3px;
  border-radius: 50%;
}
p.dashtl_p6 {
  margin: 0 10px;
  font-size: 29px;
  text-transform: capitalize;
}
.dashtl1_card_col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p.dashtl_card_p1 {
  font-size: 12px;
  margin: 0 5px;
  font-weight: 500;
  color: #9f9f9f;
}
p.dashtl_card_p2 {
  margin: 0;
    font-weight: 500;
    color: #5524fb;
    text-transform: lowercase;
}

img.dot3_svg {
  height: 25px;
  width: 6px;
  margin-left: 30px;
}
.dashtl_card_row2 {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  line-height: 30px;
}
.dashtl_smallcard {
  width: 29%;
  background: #fafafa;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
p.dashtl_p4 {
  color: #5524fb;
  font-weight: 600;
  font-size: 26px;
  margin: 0;
}
p.dashtl_p5 {
  margin: 0;
  color: #121212;
  font-weight: 500;
  font-size: 14px;
}
.dashtl_card_col3 {
  cursor: pointer;
  height: 50px;
}
.dashtl_dropdown {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 30px #9d9d9d41;
  border-radius: 5px;
  margin-left: -128px;
  margin-top: -8px;
  width: 162px;
}
.dashtl_drop1 {
  font-size: 12px;
  margin: 5px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
p.drop_p1 {
  margin: 0;
  margin-right: 10px;
}
.dashtl_header {
  /* background: #121212; */
  display: flex;
  padding: 25px;
  flex-direction: column;
  /* margin-top: 54px; */
}

p.dashtl_h_p1 {
  margin: 0;
  margin-bottom: -5px;
  color: white;
  font-size: 16px;
}

p.dashtl_h_p5 {
  color: #afafaf;
  margin: 0;
  text-align: center;
  font-size: 12px;
}
p.dashtl_h_p2 {
  color: #8f6efc;
  font-size: 15px;
  margin: 0px;
  margin-bottom: -5px;
}
.dashtl_h_col3 {
  display: flex;
  justify-content: space-between;
  width: 68%;
  height: 120px;
}
.dashtl_h_cards {
  border-radius: 10px;
  background: #946cff;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 22%;
}
p.dashtl_h_p3 {
  color: #fff;
  font-size: 18px;
  margin: 0;
  font-weight: 300;
}
p.dashtl_h_p4 {
  color: #fff;
  font-size: 35px;
  margin: 5px;
  font-weight: 600;
}
.dashtl_drop1:hover {
  background-color: #ece7fd;
  color: #121212;
  border-radius: 5px;
}
img.dash_active {
  margin: 5px;
}

.tl_list_row1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.training_assign {
  color: #fff;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
}
img.complete_track {
  width: 15px;
  height: 15px;
}
.track_tl {
  display: flex;
  justify-content: space-between;
}

.dash_tl_line {
  border-bottom: 2px dashed #8f6efc;
  width: 78px;
  position: absolute;
  margin-left: 72px;
  margin-top: 6px;
}

.dash_tl_line1 {
  border-bottom: 2px dashed #777777;
  width: 78px;
  position: absolute;
  margin-left: 182px;
  margin-top: 6px;
}
.dashtl_h_col2 {
  text-align: center;
  /* margin-left: 50px; */
}

img.back_butt {
  margin: 0px;
  /* margin-top: -30px; */
  cursor: pointer;
}
img.profile_tl {
  width: 60px;
  height: 60px;
  margin: 10px;
  border-radius: 50%;
  object-fit: cover;
}
h3.tl_list_h3 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

.dashtl_h_row2 {
  background: #333333;
  border-radius: 5px;
  padding: 10px 18px;
  margin-top: 10px;
}
img.icon_svg {
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.dashtl_h_col4 {
  width: 13%;
  display: flex;
  justify-content: space-around;
}
.tl_list_row2 {
  margin: 0px 30px;
  display: FLEX;
  justify-content: SPACE-BETWEEN;
}

.tl_dropdown {
  position: absolute;
  width: 165px;
  right: 15px;
  box-shadow: 10px 10px 30px #9d9d9d41;
  border-radius: 5px;
  z-index: 5;
  background: #fff;
  margin-top: -11px;
  padding: 2px 10px;
}
.dashtl_header1 {
  background: #121212;
  display: flex;
  padding: 25px;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.container2 {
  padding: 25px;
  background: #fafafa;
  width: 90%;
  margin: auto;
  display: flex;
}

.bar_container {
  width: 100%;
  object-fit: contain;
  contain: content;
  overflow: overlay;
  box-shadow: 0px 15px 30px #b9b9b929;
  border-radius: 20px;
}
.calender_container {
  display: flex;
  flex-direction: column;
  width: 53%;
  /* width: 40%; */
  /* padding: 20px; */
  justify-content: center;
  box-shadow: 0px 15px 30px #b9b9b929;
  border-radius: 15px;
  margin-left: 30px;
  height: 400px;
  overflow: overlay;
  border: 1px solid lightgray;
}
.bar_container {
  padding: 10px 40px !important;
  margin-left: 1px;
}
.model_contain {
  border: none;
  overflow: overlay;
}
.tablediv {
  overflow: overlay;
  contain: content;
  justify-content: center;
  display: flex;
}
.empty-cell {
  width: 15px !important;
  height: 15px !important;
}
.days {
  height: 8 !important;
  width: 15px !important;
}
tr {
  width: 40% !important;
}
.dashtl_card_row3 {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin: 2px;
  margin-left: 15px;
  width: 40%;
}
p.tllist_active {
  margin: 0;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 5px;
  color: #1da537;
  display: flex;
  justify-content: center;
  background: #afeab2;
  text-transform: capitalize;
}
.asmListTitle {
  margin-left: 10px;
  /* margin-top: 20px; */
  font-size: 30px;
  color: #db23fb;
}
.fseListTitle {
  margin-left: 10px;
  margin-top: 20px;
  font-size: 30px;
  color: #db23fb;
}
.my-card-footer {
  font-size: 8px;
  display: flex;
  justify-content: space-between;
}
.asmActionBtns {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.myRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-tab {
  padding: 4px 10px;
  margin-left: 10px;
  border: 1px solid;
  width: 100px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}
.with-loader {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.dashtl_card_row3_align{
  display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}
label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.MuiFormLabel-filled.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: 8px !important;
}
.soi-form{
  display: flex !important;
  justify-content: space-between !important;
}
/* .dashtl1_card_row {
  display: flex;
  justify-content: space-between;
  width: 50%;
} */

/* .dashtl1_row1 {
  padding: 0px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashtl_card {
  margin: 20px 0;
  box-shadow: 10px 15px 80px #dedede65;
  border-radius: 15px;
  padding: 20px;
  width: 46%;
  background: #fff;
}
.dashtl_card_row1 {
  display: flex;
  justify-content: space-between;
}
.dashtl1_card_col1 {
  display: flex;
}
img.prof_img {
  width: 100px;
  height: 100px;
  margin: 15px 3px;
  border-radius: 50%;
}
p.dashtl_p6 {
  margin: 0 10px;
  font-size: 29px;
  text-transform: capitalize;
}
.dashtl1_card_col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p.dashtl_card_p1 {
  font-size: 12px;
  margin: 0 5px;
  font-weight: 500;
  color: #9f9f9f;

}
p.dashtl_card_p2 {
  margin: 0;
  font-weight: 500;
  color: #5524fb;
}

img.dot3_svg {
  height: 25px;
  width: 6px;
  margin-left: 30px;
}
.dashtl_card_row2 {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  line-height: 30px;

}
.dashtl_smallcard {
  width: 23%;
  background: #fafafa;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
p.dashtl_p4 {
  color: #5524fb;
  font-weight: 600;
  font-size: 26px;
  margin: 0;
}
p.dashtl_p5 {
  margin: 0;
  color: #121212;
  font-weight: 500;
  font-size: 14px;
}
.dashtl_card_col3 {
  cursor: pointer;
  height: 50px;
}
.dashtl_dropdown {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 30px #9d9d9d41;
  border-radius: 5px;
  margin-left: -128px;
  margin-top: -8px;
  width: 162px;
}
.dashtl_drop1 {
  font-size: 12px;
  margin: 5px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
p.drop_p1 {
  margin: 0;
  margin-right: 10px;
}
.dashtl_header {
  background: #121212;
  display: flex;
  padding: 25px;
  flex-direction: column;
}

p.dashtl_h_p1 {
  margin: 0;
  margin-bottom: -5px;
  color: #8f6efc;
  font-size: 16px;
}

p.dashtl_h_p5 {
  color: #afafaf;
  margin: 0;
  text-align: center;
  font-size: 12px;
}
p.dashtl_h_p2 {
  color: #1da537;
  font-size: 15px;
  margin: 0px;
  margin-bottom: -5px;
}
.dashtl_h_col3 {
  display: flex;
  justify-content: space-between;
  width: 55%;
  height: 120px;
}
.dashtl_h_cards {
  border-radius: 10px;
  background: #946cff;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
p.dashtl_h_p3 {
  color: #fff;
  font-size: 18px;
  margin: 0;
  font-weight: 300;
}
p.dashtl_h_p4 {
  color: #fff;
  font-size: 35px;
  margin: 5px;
  font-weight: 600;
}
.dashtl_drop1:hover {
  background-color: #ece7fd;
  color: #121212;
  border-radius: 5px;
}
img.dash_active {
  margin: 5px;
}

.tl_list_row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.training_assign {
  color: #fff;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
}
img.complete_track {
  width: 15px;
  height: 15px;
}
.track_tl {
  display: flex;
  justify-content: space-between;
}

.dash_tl_line {
  border-bottom: 2px dashed #8f6efc;
  width: 78px;
  position: absolute;
  margin-left: 72px;
  margin-top: 6px;
}

.dash_tl_line1 {
  border-bottom: 2px dashed #777777;
  width: 78px;
  position: absolute;
  margin-left: 182px;
  margin-top: 6px;
}
.dashtl_h_col2 {
  text-align: center;
  margin-left: 50px;
}

img.back_butt {
  margin: 0px;
  margin-top: -30px;
  cursor: pointer;
}
img.profile_tl {
  width: 60px;
  height: 60px;
  margin-top: 10px;
  border-radius: 50%;
}
h3.tl_list_h3 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

.dashtl_h_row2 {
  background: #333333;
  border-radius: 5px;
  padding: 10px 18px;
  margin-top: 10px;
}
img.icon_svg {
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.dashtl_h_col4 {
  width: 13%;
  display: flex;
  justify-content: space-around;
}
.tl_list_row2 {
  margin: 0px 30px;
}

.tl_dropdown {
  position: absolute;
  width: 165px;
  right: 15px;
  box-shadow: 10px 10px 30px #9d9d9d41;
  border-radius: 5px;
  z-index: 5;
  background: #fff;
  margin-top: -11px;
  padding: 2px 10px;
}
.dashtl_header1 {
  background: #121212;
  display: flex;
  padding: 25px;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.container2 {
  padding: 25px;
  background: #FAFAFA;
  width: 90%;
  margin: auto;
  display: flex;
}

.bar_container {
  width: 100%;
  object-fit: contain;
  contain: content;
  overflow: overlay;
  box-shadow: 0px 15px 30px #B9B9B929;
border-radius: 20px;
}
.calender_container {
  display: flex;
  width: 40%;
  padding: 20px;
  justify-content: center;
  box-shadow: 0px 15px 30px #b9b9b929;
  border-radius: 15px;
  margin-left: 30px;
  height: 400px; 
  overflow: overlay;
  border: 1px solid lightgray;
}
.bar_container {
  padding: 10px 40px !important;
  margin-left: 1px;
}
.model_contain {
  border: none;
  overflow: overlay;
}
.tablediv {
  overflow: overlay;
  contain: content;
}
.empty-cell {
  width: 15px !important;
  height: 15px !important;
}
.days {
  height: 8!important;
  width: 15px !important;
}
tr {
  width: 40% !important;
}
.dashtl_card_row3 {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin: 0;
  margin-left: 15px;
}
p.tllist_active {
  margin: 0;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 5px;
  color: #1DA537;
  display: flex;
  justify-content: center;
  background: #afeab2;
  text-transform: capitalize;
} */

/* .dashtl1_card_row {
  display: flex;
  justify-content: space-between;
  width: 50%;
} */

/* .dashtl1_row1 {
  padding: 0px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashtl_card {
  margin: 20px 0;
  box-shadow: 10px 15px 80px #dedede65;
  border-radius: 15px;
  padding: 20px;
  width: 46%;
  background: #fff;
}
.dashtl_card_row1 {
  display: flex;
  justify-content: space-between;
}
.dashtl1_card_col1 {
  display: flex;
}
img.prof_img {
  width: 100px;
  height: 100px;
  margin: 15px 3px;
  border-radius: 50%;
}
p.dashtl_p6 {
  margin: 0 10px;
  font-size: 29px;
  text-transform: capitalize;
}
.dashtl1_card_col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p.dashtl_card_p1 {
  font-size: 12px;
  margin: 0 5px;
  font-weight: 500;
  color: #9f9f9f;

}
p.dashtl_card_p2 {
  margin: 0;
  font-weight: 500;
  color: #5524fb;
}

img.dot3_svg {
  height: 25px;
  width: 6px;
  margin-left: 30px;
}
.dashtl_card_row2 {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  line-height: 30px;

}
.dashtl_smallcard {
  width: 23%;
  background: #fafafa;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
p.dashtl_p4 {
  color: #5524fb;
  font-weight: 600;
  font-size: 26px;
  margin: 0;
}
p.dashtl_p5 {
  margin: 0;
  color: #121212;
  font-weight: 500;
  font-size: 14px;
}
.dashtl_card_col3 {
  cursor: pointer;
  height: 50px;
}
.dashtl_dropdown {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 30px #9d9d9d41;
  border-radius: 5px;
  margin-left: -128px;
  margin-top: -8px;
  width: 162px;
}
.dashtl_drop1 {
  font-size: 12px;
  margin: 5px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
p.drop_p1 {
  margin: 0;
  margin-right: 10px;
}
.dashtl_header {
  background: #121212;
  display: flex;
  padding: 25px;
  flex-direction: column;
}

p.dashtl_h_p1 {
  margin: 0;
  margin-bottom: -5px;
  color: #8f6efc;
  font-size: 16px;
}

p.dashtl_h_p5 {
  color: #afafaf;
  margin: 0;
  text-align: center;
  font-size: 12px;
}
p.dashtl_h_p2 {
  color: #1da537;
  font-size: 15px;
  margin: 0px;
  margin-bottom: -5px;
}
.dashtl_h_col3 {
  display: flex;
  justify-content: space-between;
  width: 55%;
  height: 120px;
}
.dashtl_h_cards {
  border-radius: 10px;
  background: #946cff;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
p.dashtl_h_p3 {
  color: #fff;
  font-size: 18px;
  margin: 0;
  font-weight: 300;
}
p.dashtl_h_p4 {
  color: #fff;
  font-size: 35px;
  margin: 5px;
  font-weight: 600;
}
.dashtl_drop1:hover {
  background-color: #ece7fd;
  color: #121212;
  border-radius: 5px;
}
img.dash_active {
  margin: 5px;
}

.tl_list_row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.training_assign {
  color: #fff;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
}
img.complete_track {
  width: 15px;
  height: 15px;
}
.track_tl {
  display: flex;
  justify-content: space-between;
}

.dash_tl_line {
  border-bottom: 2px dashed #8f6efc;
  width: 78px;
  position: absolute;
  margin-left: 72px;
  margin-top: 6px;
}

.dash_tl_line1 {
  border-bottom: 2px dashed #777777;
  width: 78px;
  position: absolute;
  margin-left: 182px;
  margin-top: 6px;
}
.dashtl_h_col2 {
  text-align: center;
  margin-left: 50px;
}

img.back_butt {
  margin: 0px;
  margin-top: -30px;
  cursor: pointer;
}
img.profile_tl {
  width: 60px;
  height: 60px;
  margin-top: 10px;
  border-radius: 50%;
}
h3.tl_list_h3 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

.dashtl_h_row2 {
  background: #333333;
  border-radius: 5px;
  padding: 10px 18px;
  margin-top: 10px;
}
img.icon_svg {
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.dashtl_h_col4 {
  width: 13%;
  display: flex;
  justify-content: space-around;
}
.tl_list_row2 {
  margin: 0px 30px;
}

.tl_dropdown {
  position: absolute;
  width: 165px;
  right: 15px;
  box-shadow: 10px 10px 30px #9d9d9d41;
  border-radius: 5px;
  z-index: 5;
  background: #fff;
  margin-top: -11px;
  padding: 2px 10px;
}
.dashtl_header1 {
  background: #121212;
  display: flex;
  padding: 25px;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.container2 {
  padding: 25px;
  background: #FAFAFA;
  width: 90%;
  margin: auto;
  display: flex;
}

.bar_container {
  width: 100%;
  object-fit: contain;
  contain: content;
  overflow: overlay;
  box-shadow: 0px 15px 30px #B9B9B929;
border-radius: 20px;
}
.calender_container {
  display: flex;
  width: 40%;
  padding: 20px;
  justify-content: center;
  box-shadow: 0px 15px 30px #b9b9b929;
  border-radius: 15px;
  margin-left: 30px;
  height: 400px; 
  overflow: overlay;
  border: 1px solid lightgray;
}
.bar_container {
  padding: 10px 40px !important;
  margin-left: 1px;
}
.model_contain {
  border: none;
  overflow: overlay;
}
.tablediv {
  overflow: overlay;
  contain: content;
}
.empty-cell {
  width: 15px !important;
  height: 15px !important;
}
.days {
  height: 8!important;
  width: 15px !important;
}
tr {
  width: 40% !important;
}
.dashtl_card_row3 {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin: 0;
  margin-left: 15px;
}
p.tllist_active {
  margin: 0;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 5px;
  color: #1DA537;
  display: flex;
  justify-content: center;
  background: #afeab2;
  text-transform: capitalize;
} */

.fseStatsCard{
  background-color: #394CC6;
}
.fseStatsToday{
  background-color: #7E8EF0;
  color: black;
    margin-top: -8px;
    height: 23px;
    width: 75px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 5px;
}
.checkedin{
  font-size: 10px;
    margin-top: 3px;
    margin-right: 10px;
    min-width: 45px;
}
td.day-cell {
    min-width: 41px !important;
    height: 10px !important;
}
.day-cell>div {
    height: 41px !important;
    text-align: right;
    padding: 1px !important;
    font-size:10px !important;
}

.day-cell>div>div {
    display: flex;
    flex-direction: column;
    height: 10px !important;
    justify-content: center;
    text-align: center;
    margin-top: 2px !important;
}
.days {
    height: 10px !important;
    text-align: center;
    color: black;
    font-size: 12px !important;
    padding-bottom: 15px;
    background-color: #f7f7f7 !important;
}
.heading-city-manager {
  color: #673695;
  font-size: 1.5em;
}

.property-box {
  height: 4em;
  opacity: 0.6;
}

.pic-container {
  display: flex;
  border: 1px solid #917ed3;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  height: 208px;
  width: 100%;
}

.need-opacity {
  opacity: 0.6;
}

.border-form {
  padding: 1%;
}

.documents-box {
  display: flex;
  justify-content: space-around;
  padding: 2%;
}

.border-box1 {
  border: 1px solid #dfdfd7;
}

.dotted-box {
  border: 1px solid #e5e7e9;
  padding: 2%;
}
/*# sourceMappingURL=form.css.map */

.addcm-container {
  margin: 22px;
  margin-top: 80px;
}

.myRowCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*----------------------------------------------------------------------------------------------------------------------*/

.documents-box {
  cursor: pointer;
}
img.image_upload_api {
  height: 90px;
}
.addcm_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.addcm_flex_col {
  display: flex;
  flex-direction: column;
}
.form-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.input-field {
  flex: 0 0 auto;
  width: 46%;
  margin-bottom: 25px;
}

.cm_label {
  margin-left: 10px;
  margin-bottom: 7px;
}

img.image_upload_profile {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
img.image_upload_api {
  height: 200px;
  width: 200px;
}

.mt-2 {
    margin-top: .5rem!important;
    display: flex;
}
.asm_button_row {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: 250px;
}
.asm_button_col2 {
    float: right;
}

.pa_sidebar {
    border: 1px solid #E0E0E0;
    background: #8f6efc45;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 190px;
}
.side_row1 {
    display: flex;
    justify-content: center;
    margin: 25px 0px;
}
.side_box {
    width: 110px;
    padding: 20px;
    padding-right: 0;
}
h3.side_h3 {
    font-size: 14px;
    margin: 0;
}
p.side_p1 {
    font-size: 11px;
    margin: 4px 0;
    color: #fff;
}
img.side_profile {
    border-radius: 50%;
    margin-left: 5px;
    object-fit: cover;
    height: 73px;
    width: 73px;
}
.side_tabs {
    cursor: pointer;
    font-size: 11px;
    background: #B59FFD2B;
    padding: 8px;
    margin: 10px 0px;
    padding-left: 25px;
    /* font-weight: 600; */
    font-size: 12px;
}

/* .side_tabs1 {
    cursor: pointer;
    font-size: 11px;
    background: #fff;
    color: #8F6EFC;
    border-right: 5px solid #121212;
    padding: 8px;
    margin: 10px 0px;
    padding-left: 25px;
} */
.side_tabs1 {
    cursor: pointer;
    background: #fff;
    color: #673695;
    border-right: 5px solid #121212;
    padding: 8px;
    margin: 10px 0px;
    padding-left: 25px;
    font-weight: 600;
    font-size: 12px;

}
.container {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 0;
    bottom: 0;
    left: 15%;
    right: 0;
    margin: auto;
  }
  
  .item {
    width: 80px;
    height: 80px;
    position: absolute;
  }
  
  .item-1 {
    background-color: #FA5667;
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
            animation: item-1_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
  }
  
  .item-2 {
    background-color: #7A45E5;
    top: 0;
    right: 0;
    -webkit-animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
            animation: item-2_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
  }
  
  .item-3 {
    background-color: #1B91F7;
    bottom: 0;
    right: 0;
    z-index: 1;
    -webkit-animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
            animation: item-3_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
  }
  
  .item-4 {
    background-color: #FAC24C;
    bottom: 0;
    left: 0;
    -webkit-animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
            animation: item-4_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
  }
  
  @-webkit-keyframes item-1_move {
    0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
    25% {-webkit-transform: translate(0, 100px);transform: translate(0, 100px)} 
    50% {-webkit-transform: translate(100px, 100px);transform: translate(100px, 100px)} 
    75% {-webkit-transform: translate(100px, 0);transform: translate(100px, 0)} 
  }
  
  @keyframes item-1_move {
    0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
    25% {-webkit-transform: translate(0, 100px);transform: translate(0, 100px)} 
    50% {-webkit-transform: translate(100px, 100px);transform: translate(100px, 100px)} 
    75% {-webkit-transform: translate(100px, 0);transform: translate(100px, 0)} 
  }
  
  @-webkit-keyframes item-2_move {
    0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
    25% {-webkit-transform: translate(-100px, 0);transform: translate(-100px, 0)} 
    50% {-webkit-transform: translate(-100px, 100px);transform: translate(-100px, 100px)} 
    75% {-webkit-transform: translate(0, 100px);transform: translate(0, 100px)} 
  }
  
  @keyframes item-2_move {
    0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
    25% {-webkit-transform: translate(-100px, 0);transform: translate(-100px, 0)} 
    50% {-webkit-transform: translate(-100px, 100px);transform: translate(-100px, 100px)} 
    75% {-webkit-transform: translate(0, 100px);transform: translate(0, 100px)} 
  }
  
  @-webkit-keyframes item-3_move {
    0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
    25% {-webkit-transform: translate(0, -100px);transform: translate(0, -100px)} 
    50% {-webkit-transform: translate(-100px, -100px);transform: translate(-100px, -100px)} 
    75% {-webkit-transform: translate(-100px, 0);transform: translate(-100px, 0)} 
  }
  
  @keyframes item-3_move {
    0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
    25% {-webkit-transform: translate(0, -100px);transform: translate(0, -100px)} 
    50% {-webkit-transform: translate(-100px, -100px);transform: translate(-100px, -100px)} 
    75% {-webkit-transform: translate(-100px, 0);transform: translate(-100px, 0)} 
  }
  
  @-webkit-keyframes item-4_move {
    0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
    25% {-webkit-transform: translate(100px, 0);transform: translate(100px, 0)} 
    50% {-webkit-transform: translate(100px, -100px);transform: translate(100px, -100px)} 
    75% {-webkit-transform: translate(0, -100px);transform: translate(0, -100px)} 
  }
  
  @keyframes item-4_move {
    0%, 100% {-webkit-transform: translate(0, 0);transform: translate(0, 0)} 
    25% {-webkit-transform: translate(100px, 0);transform: translate(100px, 0)} 
    50% {-webkit-transform: translate(100px, -100px);transform: translate(100px, -100px)} 
    75% {-webkit-transform: translate(0, -100px);transform: translate(0, -100px)} 
  }
p{
    margin-top:18px;
}
.loader_nodata{
    min-height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dash1_row3 {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}
.mystat_cards {
  width: 22%;
  height: 175px;
  background-size: contain;
  object-fit: contain;
  background-repeat: no-repeat;
  text-align: center;
}
.mystat_row1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 25px 0;
}

h3.mystat_h3 {
  margin: 0;
  margin-top: 22px;
  font-size: 18px;
  font-weight: 500;
}
h2.mystat_h2 {
  margin: 10px;
  color: #44CDDE;
  font-weight: 500;
  font-size: 32px;
}
p.mystat_p1 {
  margin: auto;
  background: #28B1CB33;
  width: 70px;
  line-height: 30px;
  border-radius: 5px;
}
.mystats {
  padding: 0 30px;
  padding-bottom: 10px;
  margin-top: 50px;
}
.mystat_card {
  display: flex;
  width: 97%;
  box-shadow: 0px 0px 20px #dddddd41;
  border-radius: 15px;
  padding: 20px;
  flex-direction: column;

}

.mystat_card1 {
  padding: 0 20px;
  width: 100%;
  border-right: 1px solid #e8e8e8;
  height: 330px;
}
.mystat_card2 {
  padding: 0 20px;
  width: 47%;
  border-top: 1px solid #e8e8e8;
  height: 300px;
}

p.mystat_p2 {
  color: #8F6EFC;
  font-weight: 500;
  margin: 0;
}
.mystat_card_row1 {
  display: flex;
}
.mystat_row3 {
  margin-bottom: 30px;
}

/*--------------------------------------------------------------------------------------------------------------------------*/

svg#SvgjsSvg1024,
svg#SvgjsSvg1001{
  margin-top: 20px;
}
.small_checkin_box {
  display: flex;
  justify-content: center;
  margin-top: -8px;

}
.small_xhexkin_xol1 {
  width: 29%;
}
p.small_checkin_p1 {
  margin: 0;
  color: #fff;
  font-weight: 900;
}
p.small_checkin_p2 {
  margin: 0;
  margin-left: 4px;
  font-weight: 600;
}
p.pro_name1 {
  color: #802df1;
  font-size: 11px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.dash_small_card {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E8FAFC;
  border: 1px solid #aadbe0;
  border-radius: 10px;
}
.myTeam_card {
    box-shadow: 0px 0px 20px #d9d9d967;
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0;
    background: #fff;
    width: 100%;
}
.myteam_container {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    margin: 30px 0;
    padding: 1px 10px;
}
.myteam_col0 {
    min-width: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

img.prof_img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
.myteam_col2 {
    min-width: 130px;
    margin: 0 10px;
    margin-top: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.myteam_card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 5px 7px 40px #dedede1a;
    border-radius: 15px;
    margin: 15px;
    padding: 20px;
    display: flex;
  }

  .myteam_p2{
    margin: 1px;
    font-size: 23px;
    font-weight: 500;
    color: #9D81FC;
}
p.myteam_p3 {
    margin: 0;
    margin-top: 5px;
}
p.myteam_p1 {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
}
img.svg_myteam {
    width: 30px;
    height: 30px;
    margin-top: -7px;
}
.myteam_col3 {
    margin: initial;
    position: absolute;
    right: 4%;
    height: 92px;
    cursor: pointer;
}
.myteam_dropdown {
    position: absolute;
    width: 165px;
    right: 70px;
    box-shadow: 10px 10px 30px #9d9d9d41;
    border-radius: 5px;
    z-index: 5;
    background: #fff;
    margin-top: 13px;
    padding: 2px 10px;
}
p.myteam_drop_p1 {
    padding: 3px;
    padding-left: 18px;
    font-size: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0;

}

.myteam_drop_p1:hover {
    background-color: #B198FF;
    color: #fff;
    padding: 5px;
    padding-left: 15px;


}
.myteam_popup {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    padding: 20px 40px;
    margin: 110px;
    margin-top: 100px;
}
h1.myteam_add_tl {
    color: #db23fb;
    font-weight: 400;
    font-size: 17px;
    margin: 0;
}
.myteam_p4{
    margin: 1px;
    font-size: 23px;
    font-weight: 500;
    color: #db23fb;
}
.myteam_button {
    padding: 10px 28px;
    border: none;
    border-radius: 5px;
    background: #9d81ff;
    color: #fff;
    font-size: 17px;
    margin: 10px;
    cursor: pointer;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
}
.myteam_col4 {
    display: flex;
    align-items: center;
}
.my_team_flex {
    display: flex;
    justify-content: flex-end;
}
.my_stats_row3 {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.dashtl_dropdown1 {
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 10px 10px 30px #9d9d9d41;
    border-radius: 5px;
    margin-left: 302px;
    margin-top: 10px;
    width: 162px;
    padding: 8px;

}

img.profile_tl2 {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 71px;
}
.tsm_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    background: #ffffff;
    max-height: 100vh;
    color: #000;
    /* contain: content; */
    /* overflow-y: auto; */
    margin-top: 17px;
}
.tsm_row1 {
    display: flex;
}
.tsm_row2 {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    margin-top: 40px;
}
.tsm_row3 {
    width: 100%;
    display: flex;
    cursor: pointer;
}
.tsm_row3:hover { 
    background-color: #ddcaff;
}
.image_profile2 {
    width: 40px;
}

.border_right1 {
    border-right: 1px solid #eeeeee;
    /* border-bottom: 4px solid black; */
    height: 40px;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: flex-start; */
    font-size: 12px;
    text-align: left;
}

.display_center {
    display: flex;
    justify-content: center;
    align-items: center;

}

.tv_dash_row22 {
    font-size: 13px;
    background: #7e57c2;
    width: 87%;
    color: #fff;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-weight: 700;
    position: fixed;
}
.myTeam_card11 {
    box-shadow: 0px 0px 20px #d9d9d967;
    border-radius: 20px;
    padding: 20px;
    margin: AUTO;
    background: #fff;
    width: 95%;
    margin-top: 75px;
}
.padding_left {
    padding-left: 20px;
    align-items: center;
}
.navbar {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 6px #88888843;
    align-items: center;
    height: 80px;

}

.nav_contain1 {
    display: flex;
}
img.nav_blink {
    width: 25px;
    height: 25px;
    margin: 0 10px;
    margin-top: 15px;
}
.nav_contain4 {
    text-align: center;
}
p.nav_p2 {
    color: #8258ED;
    margin: 0;
    /* margin-top: 15px; */
}
p.nav_p3 {
    margin: 0;
    font-size: 11px;
}
h3.nav_h3 {
    margin: 0;
    /* margin-top: 15px; */
    color: #8258ED;
    font-size: 22px;
    font-weight: 500;
    
    
}
.nav_contain2 {
    text-align: center;
}
.nav_contain5 {
    display: flex;
    justify-content: space-between;
}
p.nav_p4 {
    margin: 0 10px;
    font-size: 11px;
}
img.nav_line {
    height: 18px;
}
img.nav_company {
    height: 55px;
    width: 100px;
    object-fit: contain;
    margin-right: 20px;
}
.up_train_inner_card {
    background: #fff;
    border: 1px dashed #a0a0a0;
    border-radius: 5px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    align-content: center;
    align-items: center;
}

.update_train_card {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 400px;
    width: 90%;
}
.update_center {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
img.document_svg {
    width: 50px;
    height: 50px;
}
.setup_trainer_total,
.setup_preview_total {
    width: 95%;
}
.run_payroll_total, .launch_project_total {
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    width: 88%;
}


.first_Dash {
    width: 100%;
    height: 600px;
    display: flex;
}
.first_col1 {
    width: 15%;
    
}

.first_col2 {
    width: 100%;
}
html {
	height: 100%;
}
body {
	background-color: #f8f9fa;
	color: #333;
	font-family: "Poppins",sans-serif;
	font-size: 1rem;
	height: 100%;
	line-height: 1.6;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Poppins",sans-serif;
	margin-top: 0;
	font-size: 20px;
	font-weight: 600;
}
p.lead.mb-0 {
    font-size: 18px;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
hr {
    margin: 2rem auto;
}
.page-wrapper img {
	max-width: 100%;
}
a {
	color: #009ce7;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
.card {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.875rem;
}
.card-body {
	padding: 1.5rem;
}
.card-header {
	border-bottom: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.sidebar {
    background-color: #fff;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    transition: all 0.2s ease-in-out 0s;
    width: 280px;
    z-index: 1001;
}
.sidebar.opened {
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: 100%;
	min-height: 100%;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
    padding: 15px;
}
.sidebar-menu ul {
    font-size: 15px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
}
.sidebar-menu li a {
	color: #333;
	display: block;
	font-size: 16px;
	height: auto;
	padding: 0 20px;
}
.sidebar-menu li a:hover {
    color: #ff7901;
}
.sidebar-menu > ul > li > a:hover {
    background-color: #e8e8e8;
    color: #000;
}
.sidebar-menu > ul > li > a.active:hover {
    background-color: #ee6800;
    color: #fff;
}
.mobile_btn {
	display: none;
	float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 10px;
	white-space: nowrap;
}
.sidebar-menu ul ul a {
    display: block;
    font-size: 15px;
    padding: 7px 10px 7px 45px;
    position: relative;
}
.sidebar-menu ul ul {
	display: none;
}
.sidebar-menu ul ul ul a {
	padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
	padding-left: 85px;
}
.sidebar-menu > ul > li {
    margin-bottom: 5px;
    position: relative;
}
.sidebar-menu > ul > li:last-child {
    margin-bottom: 0;
}
.sidebar-menu > ul > li > a {
    align-items: center;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu > ul > li > a.active {
	background-color: #ff7901;
	color: #fff;
}
.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
}
.page-wrapper {
	margin-left: 280px;
	padding-top: 60px;
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper > .content {
	padding: 1.875rem 1.875rem;
}
.page-header {
	margin-bottom: 1.875rem;
}
.page-title {
    color: #333;
    margin-bottom: 5px;
}
.main-wrapper > .header {
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
    left: 310px;
    position: fixed;
    right: 50px;
    top: 50px;
    border-radius: 10px;
}
.page-wrapper {
	border-radius: 25px;
	background-color: #f5f5f5;
	padding-top: 0;
	min-height: calc(100vh - 40px);
}
.main-wrapper {
	background-color: #fff;
	height: auto;
	padding: 20px 20px 20px 0;
}
.card {
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
}
.sidebar-logo {
    display: flex;
    padding: 20px 20px 0;
    justify-content: center;
    margin-bottom: 20px;
}
.sidebar-logo a{
	padding: 10px;
}
.sidebar-logo img {
	max-height: 46px;
	width: auto;
}
.text-primary {
	color: #ff7901 !important;
}
.widget {
    background: #FFF;
    border: 1px solid #F0F0F0;
        border-left-color: rgb(240, 240, 240);
        border-left-style: solid;
        border-left-width: 1px;
    border-left: 2px solid #ff7901;
    border-radius: 4px;
    padding: 1em;
    list-style: none;
    line-height: 2;
	margin-bottom: 30px;
}
@media only screen and (max-width: 991.98px) {
	.main-wrapper {
		padding: 20px;
	}
	.sidebar {
		margin-left: -225px;
		width: 225px;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		transition: all 0.4s ease;
	}
}

@media only screen and (max-width: 767.98px) {
	body {
		font-size: 0.9375rem;
	}
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.5rem;
	}
	h4, .h4 {
		font-size: 1.125rem;
	}
	h5, .h5 {
		font-size: 1rem;
	}
	h6, .h6 {
		font-size: 0.875rem;
	}
}

@media only screen and (max-width: 575.98px) {
	.card {
		margin-bottom: 0.9375rem;
	}
	.page-wrapper > .content {
		padding: 0.9375rem 0.9375rem 0;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.page-header {
		margin-bottom: 0.9375rem;
	}
}
